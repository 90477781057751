<template>
	<Alert :message="error" v-if="error" />

	<div class="center-flex" v-if="!companyName && !error">
		<page-loading />
	</div>

	<div class="center-flex" v-if="invitationStatus == 'Accepted'">
		<p>This invitation has been accepted</p>
	</div>

	<div class="center-flex" v-if="error">
		<div class="logo">
			<img
				class="img-responsive"
				src="../../assets/rm-logo-blue.png"
				alt="logo"
			/>
		</div>
	</div>

	<div class="center-flex" v-if="invitationStatus == 'Pending'">
		<div id="form-container">
			<div class="logo">
				<img
					class="img-responsive"
					src="../../assets/rm-logo-blue.png"
					alt="logo"
				/>
			</div>
			<div class="message">
				<p>
					You have been invited to join {{ companyName }} by
					{{ employerName }}
				</p>
			</div>
			<form class="rm-form tile" @submit.prevent="handleSubmit">
				<div class="p-1rem">
					<div class="form-input">
						<label>Email</label>
						<input
							name="email"
							type="email"
							required
							v-model="email"
							disabled
						/>
					</div>
					<div class="form-input">
						<label>Full Name</label>
						<input
							name="fullName"
							type="text"
							required
							v-model="fullName"
						/>
					</div>
					<div class="form-input">
						<label>Create a Strong Password:</label>
						<input
							type="password"
							v-model="password"
							@keyup="checkPassword"
						/>
						<div class="password-hints" v-if="showChecks">
							<p :class="{ success: checks.length }">
								At least 8 characters long
							</p>
							<p :class="{ success: checks.uppercase }">
								Contains uppercase
							</p>
							<p :class="{ success: checks.lowercase }">
								Contains lowercase
							</p>
							<p :class="{ success: checks.digit }">
								Contains number
							</p>
							<p :class="{ success: checks.specialChar }">
								Contains special character
							</p>
						</div>
					</div>

					<div class="form-submit">
						<div v-if="isLoading">
							<page-loading />
						</div>
						<button
							:disabled="password.length < 8"
							class="btn btn-primary"
							type="submit"
							value="submit"
							v-if="!isLoading"
						>
							Join {{ companyName }}
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import PageLoading from "../../components/PageLoading.vue";
import Alert from "../../components/Alert.vue";
import getDocumentOnce from "../../composables/getDocumentOnce";
import { projectFirestore } from "../../firebase/config";
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import useSignup from "../../composables/useSignup";
import { projectAuth } from "../../firebase/config";
import useLogout from "../../composables/useLogout";
import { onBeforeMount } from "vue";

export default {
	components: { PageLoading, Alert },
	name: "AcceptInvite",
	props: ["id"],
	setup(props) {
		const { logout } = useLogout();
		const email = ref("");
		const fullName = ref("");
		const password = ref("");
		const employerName = ref("");
		const companyName = ref("");
		const invitationStatus = ref("");
		const confirmEmail = ref("");
		const error = ref(null);
		const showChecks = ref(false);
		const checks = ref({
			length: false,
			uppercase: false,
			lowercase: false,
			digit: false,
			specialChar: false,
		});

		// const isLoading = ref(false);
		const { isLoading, signup } = useSignup();
		const router = useRouter();

		let invitation = projectFirestore
			.collection("invitations")
			.doc(props.id);

		onMounted(async () => {
			// logout and remove cookie  in case using a logged in browser
			const { error: inviteErr, document } = await getDocumentOnce(
				invitation
			);

			if (document.value) {
				email.value = document.value.employeeEmail;
				employerName.value = document.value.employerName;
				companyName.value = document.value.companyName;
				invitationStatus.value = document.value.status;
			}

			if (inviteErr.value) {
				error.value = "Failed to find an invitation";
			}
		});

		const checkPassword = () => {
			showChecks.value = true;
			checks.value = {
				length: password.value.length >= 8,
				uppercase: /[A-Z]/.test(password.value),
				lowercase: /[a-z]/.test(password.value),
				digit: /[0-9]/.test(password.value),
				specialChar: /[!@#$%^&*]/.test(password.value),
			};
		};

		const handleSubmit = async () => {
			try {
				error.value = null;

				email.value = email.value.trim().toLowerCase();
				fullName.value = fullName.value.trim();
				password.value = password.value.trim();

				console.log("email", email.value);
				console.log("fullName", fullName.value);

				// Check if the user is already logged in
				// Can happen when people use shared computers
				if (projectAuth.currentUser != null) {
					logout();
				}

				if (!Object.values(checks.value).every((check) => check)) {
					error.value = "Weak Password";
					isLoading.value = false;
					return;
				}

				// Passing the invitation id keeps track of invitation flow drop offs
				await signup(
					email.value,
					fullName.value,
					password.value,
					props.id
				);

				// Wait for the authentication state to update
				await new Promise((resolve) => setTimeout(resolve, 1000));

				if (!error.value) {
					const currentUser = projectAuth.currentUser;
					if (currentUser) {
						await currentUser.sendEmailVerification();
						router.push({
							name: "VerifyEmail",
						});
					} else {
						// If null, the user can send again on the verify email page
						router.push({
							name: "VerifyEmail",
						});
					}
				}
			} catch (e) {
				console.error("Error accepting invitation: ", e);
				error.value = e.message;
				isLoading.value = false;
				return;
			}
		};

		return {
			email,
			fullName,
			password,
			employerName,
			companyName,
			error,
			isLoading,
			handleSubmit,
			invitationStatus,
			confirmEmail,
			checks,
			showChecks,
			checkPassword,
		};
	},
};
</script>

<style lang="scss" scoped>
.password-hints {
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	margin-top: 0.5em;
	margin-left: 0.5em;
	p {
		font-size: 0.8em;
		color: $rm-grey;
	}
	.success {
		color: $rm-success;
	}
}

.center-flex {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100vh;
}

.logo {
	width: 100%;
	padding: 2em 0;
	margin-top: -15vh;

	img {
		width: 180px;
	}
}

.tile {
	margin: 1em;
}

.rm-form {
	min-width: 30vw;

	.form-input {
		input {
			&::placeholder {
				color: $rm-grey !important;
			}
		}
	}
}

.message {
	padding: 0 2em 2em;
}

.form-input {
	position: relative;
}

.email-confirmed {
	position: absolute;
	top: 28px;
	right: 0;
	color: $rm-success;
	font-size: 1.5em;
}
</style>
