import { ref } from "@vue/reactivity";
import { projectAuth, projectFirestore } from "../firebase/config";
const isLoading = ref(false);
const error = ref("");

// Import Firebase GoogleAuthProvider from compat
import firebase from "firebase/compat/app";

const login = async (email, password) => {
	error.value = null;
	isLoading.value = true;

	// Sign the user in
	try {
		await projectAuth.signInWithEmailAndPassword(email, password);
	} catch (err) {
		console.log(err.code);

		switch (err.code) {
			case "auth/invalid-credential":
				error.value =
					"Invalid credential. Please check your email and password.";
				break;
			case "auth/too-many-requests":
				error.value = "Too many login attempts. Try again later.";
				break;
			default:
				error.value =
					"Something went wrong, please try again later or contact us.";
		}
		isLoading.value = false;
	}
};

const googleLogin = async () => {
	error.value = null;
	isLoading.value = true;
	try {
		// Check if a user is currently logged in
		if (projectAuth.currentUser) {
			// Sign out the current user
			await projectAuth.signOut();
			console.log("User signed out");
		}

		const provider = new firebase.auth.GoogleAuthProvider();

		await projectAuth.signInWithPopup(provider);

		// Sync google profile info with firestore
		// const user = projectAuth.currentUser;
		// console.log("Provider data: ", user.providerData);

		// let googeProfileData;
		// let passwordProfileData;

		// for (let profile of user.providerData) {
		// 	if (profile.providerId === "google.com") {
		// 		googeProfileData = profile;
		// 		break;
		// 	} else if (profile.providerId === "password") {
		// 		passwordProfileData = profile;
		// 		break;
		// 	}
		// }

		// const userRef = projectFirestore.collection("users").doc(user.uid);
		// const doc = await userRef.get();

		// const batch = projectFirestore.batch();

		// if (doc.exists) {
		// 	// Update user profile
		// 	await user.updateProfile({
		// 		displayName: googeProfileData.displayName,
		// 		photoURL: googeProfileData.photoURL,
		// 	});

		// 	const employeeRef = projectFirestore
		// 		.collection("companies")
		// 		.doc(doc.data().companyId)
		// 		.collection("employees")
		// 		.doc(user.uid);

		// 	batch.set(
		// 		employeeRef,
		// 		{
		// 			profile: {
		// 				fullName: googeProfileData.displayName,
		// 				profileImage: googeProfileData.photoURL,
		// 			},
		// 		},
		// 		{ merge: true }
		// 	);

		// 	batch.set(
		// 		userRef,
		// 		{
		// 			fullName: googeProfileData.displayName,
		// 		},
		// 		{ merge: true }
		// 	);

		// 	await batch.commit();
		// }
	} catch (err) {
		console.log(err.message);
		error.value = "Failed to sign in with Google";
		isLoading.value = false;
	}
};

const useLogin = () => {
	return { error, isLoading, login, googleLogin };
};

export default useLogin;
