<template>
	<Alert :message="error" v-if="error" />
	<div class="container">
		<div class="container-left display-lg">
			<div class="vertical-center">
				<div class="left-text">
					<div></div>

					<div class="steps">
						<h3><fa icon="check-square" />&nbsp;Sign up</h3>
						<div class="steps-spacer"></div>
					</div>

					<div class="steps" v-if="newCompanySignup != false">
						<h3>
							<fa icon="check-square" />&nbsp;Add Company Details
						</h3>
						<div class="steps-spacer"></div>
					</div>

					<div class="spacer-20"></div>

					<h2>Verify your email</h2>
					<div class="spacer-20"></div>
					<p>
						We need to verify your email address to make sure that
						you're a real human with good intentions.
					</p>

					<div class="spacer-20"></div>
				</div>
			</div>
		</div>
		<div class="container-right">
			<div class="vertical-center">
				<div class="message tile" v-if="verifying">
					<PageLoading />
				</div>
				<div class="message tile" v-else-if="!verified">
					<h2>We've sent you a verification email</h2>
					<br />
					<p>
						Click the link in the email, return here and click "I've
						verified my email". If you don't see the email, check
						your spam folder.
					</p>
					<br />
					<br />
					<div class="split">
						<button
							class="btn btn-primary"
							@click="sendVerification"
							v-if="showResendBtn"
						>
							Re-send verification
						</button>
						<div v-else class="help-text">Email Sent</div>
						<button
							class="btn btn-success"
							@click="emailVerifiedCheck"
						>
							I've verified my email
						</button>
					</div>
				</div>
				<!-- Email is verified -->
				<div class="message tile" v-else>
					<fa class="success check-large" icon="check-circle" />
					<h2>Email verified!</h2>
					<div class="spacer-20"></div>
					<p>
						You're all set to download and log in to the Risk Memo
						App
					</p>
					<br />
					<div class="split">
						<a
							href="https://apps.apple.com/in/app/risk-memo/id1448345395"
							class="btn btn-primary"
							>iOS</a
						>
						<a
							href="https://play.google.com/store/apps/details?id=com.riskmemo.riskmemo"
							class="btn btn-primary"
							>Android</a
						>
					</div>
					<br />
					<!-- Only display on desktop -->
					<div class="display-lg">
						<p>
							Or proceed to the
							<router-link :to="{ name: 'TemplatesDash' }"
								>desktop app</router-link
							>
						</p>
						<div class="spacer-20"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { projectAuth } from "../../firebase/config";
import { useRouter, useRoute } from "vue-router";
import PageLoading from "../../components/PageLoading.vue";
import getUser from "../../composables/getUser";
import Alert from "../../components/Alert.vue";

export default {
	components: { PageLoading, Alert },
	name: "VerifyEmail",
	setup() {
		const router = useRouter();
		const route = useRoute();
		const verified = ref(false);
		const verifying = ref(false);
		const showResendBtn = ref(true);
		const error = ref(null);
		const { user } = getUser();

		const newCompanySignup = route.query.newCompanySignup ?? false;

		const handleShowResendBtn = () => {
			showResendBtn.value = false;
			setTimeout(() => {
				showResendBtn.value = true;
			}, 30000);
		};

		const sendVerification = () => {
			verifying.value = true;

			if (!projectAuth.currentUser) {
				error.value =
					"Authentication error. Please log in at app.riskmemo.com/login or contact us.";
				setTimeout(() => {
					verifying.value = false;
				}, 2000);
				return;
			}

			projectAuth.currentUser
				.sendEmailVerification()
				.then(() => {
					handleShowResendBtn();
				})
				.catch((err) => {
					setTimeout(() => {
						verifying.value = false;
					}, 2000);
					error.value =
						"Failed to send verification email. Try again later.";
				});
			setTimeout(() => {
				verifying.value = false;
			}, 2000);
		};

		const emailVerifiedCheck = () => {
			error.value = null;
			verified.value = false;
			verifying.value = true;

			projectAuth.currentUser
				.reload()
				.then(() => {
					if (projectAuth.currentUser.emailVerified) {
						verified.value = true;
					} else {
						error.value =
							"Email not verified. Please verify your email.";
					}
				})
				.catch(() => {
					error.value = "Email check error. Refresh and try again.";
					setTimeout(() => {
						verifying.value = false;
					}, 2000);
				});
			setTimeout(() => {
				verifying.value = false;
			}, 2000);
		};

		return {
			sendVerification,
			handleShowResendBtn,
			verified,
			error,
			verifying,
			newCompanySignup,
			showResendBtn,
			emailVerifiedCheck,
		};
	},
};
</script>

<style lang="scss" scoped>
.display-lg {
	display: none;

	@include respond(large) {
		display: block;
	}
}

.help-text {
	padding: 10px;
	font-size: 0.8em;
	font-weight: bold;
	color: $rm-grey;
}

.container-right {
	width: 100vw;

	.logo-container {
		width: 100%;
		text-align: center;
		margin-top: -80px;
		margin-bottom: 30px;
		.logo {
			width: 60px;
		}
	}

	@include respond(medium) {
		width: 50vw;
		.logo {
			width: 80px;
		}
	}
}

.message {
	text-align: center;
	width: 55ch;
	max-width: 90vw;
}

.check-large {
	font-size: 2.5em;
	margin-bottom: 20px;
}
</style>
